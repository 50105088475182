.gray-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Gray background with 50% opacity */
  z-index: 4; /* Ensure it appears above other elements but below the popup */
}
.pop-out {
  position: fixed;
  width: 80%;
  height:80%;
  top: 50%;
  cursor: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--temp-color);
  color: var(--secondary-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 999;

   @media screen and (max-width: 600px) {
      h1{
        font-size: 1rem;
      }
     // background-color: white;
     width: 70%;
     height: 40%;
    }
}

.content {
  //position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   height: 100%;
   
   
}

.close-btn {
  position: absolute;
  top: 5px;
  cursor: pointer;
  right: 5px;
  font-weight: bold;
}
.AiOutlineCloseIcon{
   color: var(--secondary-color);
   width: 45px;
   height: 45px;
     @media screen and (max-width: 400px) {
       width: 23px;
      height: 23px;
    }
    
    &:hover{
     //text-shadow: 0 0 50px 0 var(--secondary-color);
     color : var(--white-color);;
    }
}


.video{

   margin-top: 10px;
      width: 100%;
     height: 100%;
    iframe{
     width: 100%;
     height: 100%;


    
    }
}