.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;

  h2 {
    color: var(--temp-color);
  }
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 4rem 0 2rem;

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      //padding: 1rem 2rem;
      //border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item {
    width: 270px;
    flex-direction: column;

    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    position: relative;

    cursor: pointer;
    transition: all 0.3s ease;



    .showNewImage {
      //display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      z-index: 1;
      color: var(--temp-color);
    }

    .RemoveNewImage {
      display: none;
    }

    &.recent {
       animation: jumpAnimation 1s infinite, glowing 1s infinite; /* Applying both animations */
      /* Applying the jump animation with infinite repetition */
      @keyframes glowing {
        0% {
          box-shadow: 0 0 5px var(--secondary-color);
        }

        50% {
          box-shadow: 0 0 20px var(--secondary-color);
        }

        100% {
          box-shadow: 0 0 5px var(--secondary-color);
        }
      }
    }

    &:hover {
      box-shadow: 0 0 30px 0 var(--temp-color);
    }

    @media screen and (min-width: 2000px) {
      //   width: 470px;
      //  padding: 1.25rem;
      // border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app__work-img {
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  position: relative;

  .app__work-hyerlink {
    background-color: black;
  }



  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }


  @media screen and (min-width: 2000px) {
    //   height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 0.1rem;
    line-height: 1.5;
    color: var(--temp-color);

    @media screen and (min-width: 2000px) {
      // margin-top: 3rem;
    }
  }

  p {
    text-align: justify;
  }

  .app__work-stack {
    display: flex;
    flex-direction: column;

    //  background-color: black;
    h5 {
      margin-top: 10px;
      font-stretch: expanded;
      //  background-color: red;
      transform: scaleX(1.2);
      /* Adjust the value to control the width */

    }

    .app__work-stack-tags {
      // background-color: red;
      //  border: 2px solid green;
      padding: .5rem;

      //width: min(80vw,600px);
      margin-top: 0.3em;

      .app__work-stack-item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        // background-color: black;
        gap: .3em;

        span {
          color: #edeec9;
          font-size: 0.7rem;
          display: inline-block;
          border-radius: 4px;
          padding: .25em .8em;
          background-color: #9c73a8;
        }
      }
    }

  }



  .app__work-tag {
    position: absolute;
    //background-color: black !important;
    padding: .5rem 1rem;
    border-radius: 10px;
    background-color: #fff;
    top: -250px;
  }

  .app__work-hyerlink {
    //  background-color: black;
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;




    a {
      color: var(--temp-color);
      font-size: 20px;

      &:hover {
        color: var(--secondary-color);
      }
    }
  }

}