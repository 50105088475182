#home {
  .justified-text {
    text-align: justify;
  }
  
    position: relative;
    //background: url("../../assets/background.png");
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
  
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }
  
  .app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
  
    padding: 6rem 2rem 0rem;
  
    @media screen and (min-width: 2000px) {
      padding-top: 8rem;
    }
  
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  
    @media screen and (max-width: 450px) {
      padding: 6rem 1rem 2rem;
     // background-color: green;
    }
  }
  
  .app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  
    margin: 0 2rem;
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0rem;
    }
  }
  
  .app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
   
    p{
      color: var(--temp-color);
    }
    .badge-cmp {
      padding: 1rem 2rem;
      background: var(--temp-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
     // background-color: green;
  
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      img{
        width: 100px;
        height: 100px;

        @media screen and (max-width: 500px) {
          width: 50px;
          height: 50px;
        }
      }
      p{
        color: var(--white-color);
      }
      h1{
        color: var(--secondary-color);

        @media screen and (max-width: 650px) {
          font-size: 1.5rem;
        }
      }

      @media screen and (max-width: 400px) {


      }


    }
  
    .tag-cmp {
      flex-direction: column;
      margin-top: 3rem;
      p {
        width: 100%;
        text-transform: uppercase;
        text-align: right;
        color: var(--white-color);
    
      }
    }
  
    
    span {
      font-size: 2.5rem;
  
      @media screen and (min-width: 2000px) {
        font-size: 5rem;
      }
    }
  
    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
  
    div {
      width: 100px;
      height: 100px;
      border-radius: 50%;
  
      background: var(--white-color);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  
      img {
        width: 60%;
        height: 60%;
      }
    }
  
    // circles with different height & width
  
    div:nth-child(1) {
      width: 100px;
      height: 100px;
    }
  
    div:nth-child(2) {
      margin: 1.75rem;
      width: 150px;
      height: 150px;
    }
  
    div:nth-child(3) {
      width: 70px;
      height: 70px;
    }
  
    @media screen and (min-width: 2000px) {
      div:nth-child(2) {
        width: 400px;
        height: 400px;
      }
  
      div:nth-child(3) {
        width: 170px;
        height: 170px;
      }
  
      div:nth-child(1) {
        width: 200px;
        height: 200px;
      }
    }
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 0;
  
      div {
        margin: 1rem;
      }
    }
  }
  
  .app__header-img {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    
 
    .tag-cmp {

      padding: 1rem 2rem;
      text-align: center;
      background: var(--temp-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
      margin-top: 3rem;
      margin-bottom: 3rem;
  
      img{
        width: 30px;
        height: 30px;
      }
  
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      p{
        color: var(--white-color);
        width: 100%;
        text-transform: uppercase;
        text-align: right;
        color: var(--white-color);
      }
      h1{
        color: var(--white-color);
      }
      @media screen and (max-width: 600px) {
        width: 100%;
        //background-color: green;
        height: 100%;
        flex-direction: row;
        //background-color: green;
        flex-wrap: wrap;
        margin-left: 0;
        margin-bottom: 2rem;
        div {
          margin: 1rem;
        }
        h1{
          font-size: 1rem;
        }
      }



    }
  }
  